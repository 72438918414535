import template1 from './template-1'
import template2 from './template-2'
import template3 from './template-3'
import template4 from './template-4'
import template5 from './template-5'
import template6 from './template-6'
import template7 from './template-7'
import template8 from './template-8'
import templateAccounts from './template-accounts'
import templateLoginSignup from './template-login-signup'
import brandOverrides from './brand-overrides'

const overrides = brandOverrides[process.env.NEXT_PUBLIC_SITE]

export default {
  template_1: template1,
  template_2: template2,
  template_3: template3,
  template_4: template4,
  template_5: template5,
  template_6: template6,
  template_7: template7,
  template_8: template8,
  template_accounts: templateAccounts,
  template_login_signup: templateLoginSignup,
  ...overrides,
}
