export default {
  textAlign: 'left',
  textWidth: 'standard',
  backgroundColours: {
    first: '/primary300',
    middle: [
      '/primary100',
      '/white',
      '/tertiary100',
      '/tertiary100',
      '/white',
    ],
    last: '/primary300',
  },
  backgroundOverrides: {
    helpSnippets: '/white',
    quote_grid: '/primary200',
    orderSummary: '/tertiary100',
  },
  decorations: {
    first: 'None',
    middle: [
      'None',
    ],
  },
  modules: {},
}
