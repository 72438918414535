import bnssgTemplate2 from './bnssg/template-2'
import bnssgTemplate3 from './bnssg/template-3'
import hcrgTemplate1 from './hcrg/template-1'
import hcrgTemplate2 from './hcrg/template-2'
import hcrgTemplate3 from './hcrg/template-3'

export default {
  hcrg: {
    template_1: hcrgTemplate1,
    template_2: hcrgTemplate2,
    template_3: hcrgTemplate3,
  },
  bnssg: {
    template_2: bnssgTemplate2,
    template_3: bnssgTemplate3,
  },
}
