export default {
  textAlign: 'left',
  textWidth: 'standard',
  backgroundColours: {
    first: '/black300',
    middle: [
      '/white',
    ],
    last: '/white',
  },
  backgroundOverrides: {},
  decorations: {
    first: 'None',
    middle: [
      '/curve/quarter/right',
      '/curve/quarter/rightInverse',
      '/curve/quarter/right',
      '/curve/semiInverse',
    ],
  },
  modules: {},
}
